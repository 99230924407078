(function($){
  $(function(){

    $('.carousel').carousel({
      interval: 4000,
      wrap: true
    });


    $('.sponsor-slider').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 2000,
      variableWidth: true
    });

    if ($(window).width() >= 768) {
      if ($('.no-touch')) {
        $('.dropdown-toggle').on("click", function(e) {
          $('.navbar-nav li').removeClass("open");
          $('.navbar-nav li ul').hide();
        });

        $('.navbar-nav').on('mouseenter', '> li', function(e){
          $('.header-subnav ul').slideUp('fast');
          $('.header-subnav').slideUp('fast');
          name = "#subnav-"+($(this).prop('id'));
          if ($(name).length > 0) {
            $('.header-subnav').slideDown('fast');
            $(name).slideDown('fast');
          }
        });
      }
    }

    var api;
    $(document).ready(function() {
      api = $(".fullwidthbanner").apexSlider({
        startWidth:1000,
        startHeight:575,

        delay: 9000,
        transition:"fade",

        thumbWidth:100,  //Thumb Width, Height and Amount (If navigation type set to thumb)
        thumbHeight:47,
        thumbAmount:5,

        navType:"both",                                         //bullet, thumb, none, both
        navStyle:"round",  //round, repeatound-blue, round-black, number
        navArrow:"visible",  //visible,both hidden
        showNavOnHover:true,
        navOffsetVertical:10,

        timerAlign:"top",
        pauseOnHover:false,

        shadow: 0,

        shadow:3,

        fullWidth:true
      });
    });

  });
})(jQuery);
