import "index.scss";

import "./bootstrap/affix";
import "./bootstrap/alert";
import "./bootstrap/button";
import "./bootstrap/carousel";
import "./bootstrap/collapse";
import "./bootstrap/dropdown";
import "./bootstrap/tab";
import "./bootstrap/transition";
import "./bootstrap/scrollspy";
import "./bootstrap/modal";
import "./bootstrap/tooltip";
import "./bootstrap/popover";

import "./jquery.flashblue-plugins.js";
import "./jquery.apex-slider.js";
import "./site";

// import "./modernizr.custom.68554.js";

// Import all javascript files from src/_components
const componentsContext = require.context("bridgetownComponents", true, /.js$/);
componentsContext.keys().forEach(componentsContext);
